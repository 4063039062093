import React, { useState, useEffect, useRef, useCallback } from 'react';
import './HowToBuy.css';

function HowToBuy() {
    const [isVisible, setIsVisible] = useState({ step1: false, step2: false, step3: false });

    // Directly creating refs in the component body
    const step1Ref = useRef(null);
    const step2Ref = useRef(null);
    const step3Ref = useRef(null);

    const handleScroll = useCallback(() => {
        // Array to hold all refs and their keys
        const refs = { step1: step1Ref, step2: step2Ref, step3: step3Ref };

        Object.keys(refs).forEach(key => {
            const viewPosition = window.innerHeight / 1.3;
            const elementPosition = refs[key].current.getBoundingClientRect().top;

            if (elementPosition < viewPosition) {
                setIsVisible(prev => ({ ...prev, [key]: true }));
            } else {
                setIsVisible(prev => ({ ...prev, [key]: false }));
            }
        });
    }, [step1Ref, step2Ref, step3Ref]);  // Including refs directly

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <section id="how-to-buy">
    <h2>To the moon ❤️<h1>"Y Tho"</h1></h2>
    <div className="step-container">
        <div className="step" ref={step1Ref}>
            <p className={isVisible.step1 ? 'fade-in' : 'fade-out'}>
            When you're not baffling over senseless market trends or dodging irrational market dumps in crypto's unpredictable waters, you can kick back and enjoy a good "Y Tho" meme. Realistic reenactments of inexplicable crypto dips paired with our iconic "Y Tho" commentary might even make your real-world friends chuckle. (Just make sure they understand the meme before they question your investment choices!)
            </p>
        </div>
        <div className="step" ref={step2Ref}>
            <h3>Quick Hint</h3>
            <p className={isVisible.step2 ? 'fade-in' : 'fade-out'}>
            To navigate the chaotic waves of crypto, you'll sometimes need to wear your "Y Tho" glasses. Peering through them, you might spot an absurdity or two in the market's logic. Call out the ridiculous with your best meme voice and watch as the community rallies around the humor.
            </p>
        </div>
        <div className="step" ref={step3Ref}>
            <h3>??????</h3>
            <p className={isVisible.step3 ? 'fade-in' : 'fade-out'}>
            In the world of crypto, why settle for normal when you can go full "Y Tho"? The market may go up, down, or sideways in directions that defy explanation. With each turn, your meme-inspired portfolio might just become the next viral sensation. Ready to meme your way to the moon? 🚀
            </p>
        </div>
        <div className="step">
            <div class="text-overlay">Hold up, this market move... Y Tho? 👀</div> 
        
            
            <div className="card-image">
                <img src="./assets/ytho16.svg" alt="Y Tho Meme" className="meme-image" />
            </div>
        </div>
        <div className="socials">
            <p>JOIN THE MEME WAVE</p>
            <div className="icons">
                <a href="https://t.me/YthoSolana" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-telegram"></i>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                </a>
                <a href="https://dexscreener.com/solana/bi8hrzbbh9jc7d2mkvxw4k6ekpno2pgc82nwffk54czg" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/dex-screener-seeklogo.png" alt="Dexscreener" style={{ width: '24px', height: '24px' }}/>
                </a>
            </div>
        </div>
    </div>
</section>

    );
}

export default HowToBuy;
