import React from 'react';
import './WikiLink.css';

const WikiLink = () => {
    return (
        <a href="https://jup.ag/swap/AfXRqsG4PhEJyXfNiqeZf9cWVNkSA9YannF1ttvP9fsm-SOL" target="_blank" rel="noopener noreferrer" className="wiki-link" aria-label="Buy Now">
            → Buy Now
        </a>
    );
}

export default WikiLink;
