import React, { useEffect, useState } from 'react';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';
import './App.css';
import Footer from './components/Footer';
import Box from './components/Box';
import HowToBuy from './components/HowToBuy';
import Tokenomics from './components/Tokenomics';
import WikiLink from './components/WikiLink';
import SimonCard from './components/SimonCard';
import ImageGallery from './components/ImageGallery';

function App() {
    const [showBubbles, setShowBubbles] = useState(false);

    useEffect(() => {
        if (Cookies.get('CookieConsent')) {
            console.log('User has consented to cookies');
        } else {
            console.log('User has not consented to cookies');
        }

        // Show bubbles on every page load
        setShowBubbles(true);

        
        const bubbleTimeout = setTimeout(() => {
            setShowBubbles(false);
        }, 40000);

        // Cleanup the timeout on component unmount
        return () => clearTimeout(bubbleTimeout);
    }, []);

    return (
        <div className="overall-wrapper">
            <div className="App">
                
                <Box />
                <SimonCard />
                <div id="how-to-buy-section" className="how-to-buy">
                    <HowToBuy />
                </div>
                <Tokenomics />
                <div id="wiki-link-section" className="wiki-link-container">
                    <WikiLink />
                </div>
                <Footer />
            </div>
            <div id="image-gallery-section" className="image-gallery">
                <ImageGallery />
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                enableDeclineButton
                style={{ background: "#8B0000", color: "#FFFFFF" }}
                buttonStyle={{ background: "#FF6347", color: "#FFFFFF", fontSize: "13px", borderRadius: "5px" }}
                declineButtonStyle={{ background: "#000000", color: "#FFFFFF", fontSize: "13px", borderRadius: "5px" }}
                expires={150}
                onAccept={() => { Cookies.set('CookieConsent', 'true', { expires: 150 }); }}
                onDecline={() => { Cookies.remove('CookieConsent'); }}
            >
                This website uses cookies to enhance the user experience.{" "}
                <span style={{ fontSize: "10px" }}> By clicking "Accept", you consent to the use of all cookies. For more information, please review our <a href="/privacy-policy" style={{ color: "#FFFFFF", textDecoration: "underline" }}>Privacy Policy</a>.</span>
            </CookieConsent>
            {showBubbles && (
                <div className="wrapper">
                    <div><span class="dot"><span class="bubble-text">Y</span></span></div>
                    <div><span class="dot"><span class="bubble-text">The</span></span></div>
                    <div><span class="dot"><span class="bubble-text">THO</span></span></div>
                    <div><span class="dot"><span class="bubble-text">To</span></span></div>
                    <div><span class="dot"><span class="bubble-text">MOON</span></span></div>  
                </div>
            )}
        </div>
    );
}

export default App;
