import React, { useState } from 'react';
import './Footer.css';

function Footer() {
    const [imageSize, setImageSize] = useState(200);  // Default image size
    const [spinSpeed, setSpinSpeed] = useState(1);    // Initial speed set to mid-range for visibility
    const [spinDirection, setSpinDirection] = useState('normal');  // Default spin direction

    const handleSizeChange = (event) => {
        setImageSize(event.target.value);
    };

    const handleSpeedChange = (event) => {
        setSpinSpeed(event.target.value);
    };

    const handleDirectionChange = (event) => {
        setSpinDirection(event.target.value);
    };

    return (
        <footer>
            <div className="footer-image-container" style={{
                animation: `spin ${spinSpeed}s linear infinite`,
                animationDirection: spinDirection,
                maxWidth: `${imageSize}px`
            }}>
                <img src="./assets/ytho17.png" alt="Descriptive Alt Text" className="footer-image"/>
            </div>
            <div className="controls">
                <label>
                    Image Size:
                    <input type="range" min="50" max="600" value={imageSize} onChange={handleSizeChange} />
                </label>
                <label>
                    Spin Speed (faster to slower):
                    <input type="range" min="0.1" max="5" value={spinSpeed} onChange={handleSpeedChange} step="0.1" />
                </label>
                <label>
                    Spin Direction:
                    <select value={spinDirection} onChange={handleDirectionChange}>
                        <option value="normal">Normal</option>
                        <option value="reverse">Reverse</option>
                    </select>
                </label>
            </div>
        </footer>
    );
}

export default Footer;
