import React, { useState, useEffect } from 'react';
import './ImageGallery.css';

function ImageGallery() {
    const images = [
        "./assets/gallery/g1.png",
        "./assets/gallery/g2.png",
        "./assets/gallery/g3.png",
        "./assets/gallery/g4.png",
        "./assets/gallery/g5.png",
        "./assets/gallery/g6.png",
        "./assets/gallery/g7.png",
        "./assets/gallery/g8.png",
        "./assets/gallery/g9.png",
        "./assets/gallery/g10.png",
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [speed, setSpeed] = useState(10000); // Transition speed in milliseconds
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            if (!isPaused) {
                setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
            }
        }, speed);

        return () => clearInterval(timer);
    }, [speed, isPaused, images.length]);

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
        setIsPaused(true);
    };

    const handleSpeedChange = (event) => {
        setSpeed(Number(event.target.value));
    };

    const togglePause = () => {
        setIsPaused(!isPaused);
    };

    return (
        <div className="image-gallery">
            <div className="carousel">
                <div className="image-card">
                    <img src={images[currentIndex]} alt={`Display ${currentIndex}`} style={{ width: '100%', height: 'auto' }} />
                </div>
            </div>
            <div className="controls">
                <button onClick={togglePause}>{isPaused ? 'Resume' : 'Pause'}</button>
                <label>
                    looping speed: {speed}ms
                    <input type="range" min="500" max="5000" value={speed} onChange={handleSpeedChange} />
                </label>
            </div>
            <div className="thumbnails">
                {images.map((src, index) => (
                    <img key={index}
                        src={src}
                        alt={`Thumbnail ${index}`}
                        onClick={() => handleThumbnailClick(index)}
                        className={currentIndex === index ? 'active-thumbnail' : 'thumbnail'}
                        style={{ cursor: 'pointer', opacity: currentIndex === index ? 1 : 0.6, transition: 'opacity 0.3s' }}
                    />
                ))}
            </div>
        </div>
    );
}

export default ImageGallery;
