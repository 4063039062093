import React, { useState } from 'react';
import './SimonCard.css';

function SimonCard() {
  const [imageSize, setImageSize] = useState(200); // Default size in pixels

  const handleSizeChange = (event) => {
    setImageSize(event.target.value);
  };

  return (
    <div className="simon-card">
      <div className="card-image" style={{ maxWidth: `${imageSize}px` }}>
        <img src="./assets/ytho13.svg" alt="Simon" />
      </div>
      <input
        type="range"
        min="100"
        max="300"
        value={imageSize}
        onChange={handleSizeChange}
      />
    </div>
  );
}

export default SimonCard;
