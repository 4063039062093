import React from 'react';
import './Box.css';  // Ensure your CSS file is named appropriately

const Box = () => {
    return (
        <div className="container3">
            <div className="glitch" data-text="You Are A.... Cat">
                y.... <span>tho</span>
            </div>
        </div>
    );
}

export default Box;
